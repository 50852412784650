import React from 'react'
import './brand.css'
import {
  python,
  sklearn,
  xgboost
} from "./imports"

const Brand = () => {
  return (
    <div className='iq__brand section__padding'>
      <div>
        <img src={python} alt="google" />
      </div>

      <div>
        <img src={sklearn} alt="slack" />
      </div>

      <div>
        <img src={xgboost} alt="atlassian" />
      </div>

    </div>
  )
}

export default Brand