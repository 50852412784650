import React from 'react'
import { Link } from 'react-router-dom'
import possibilityImage from '../../assets/possibility.png'
import "./possibility.css"
const Possibility = (props) => {

  if (props.loggedIn && (props.subscriptionStatus === "active" || props.subscriptionStatus === "LIFETIME")) {
    return (
      <div className='iq__possibility section__padding' id='possibility'>

        <div className='iq__possibility-content'>
          <div className='iq__possibility-image'>
            <img src={possibilityImage} alt="possibility" />
          </div>
          <h1 className="gradient__text">Predictions Built From Historical Data!</h1>
          <p>Our machine learning model creates predictions by learning from thousands of fights,
            dating all the way back to 2003. Learning from the fight statistics and fighters to produce
            accurate percentage based predictions.</p>
          <Link to={"/predictions"}> <h4 className='iq__sub-cta'>View Dashboard</h4></Link>

        </div>

      </div>
    )
  }
  if (props.loggedIn && props.subscriptionStatus === "Inactive") {
    return (
      <div className='iq__possibility section__padding' id='possibility'>
        <div className='iq__possibility-image'>
          <img src={possibilityImage} alt="possibility" />
        </div>
        <div className='iq__possibility-content'>

          <h1 className="gradient__text">Predictions Built From Historical Data!</h1>
          <p>Our machine learning model creates predictions by learning from thousands of fights,
            dating all the way back to 2003. Learning from the fight statistics and fighters to produce
            accurate percentage based predictions.</p>
          <Link to={"/account"}> <h4 className='iq__sub-cta'>Subscribe For Access</h4></Link>

        </div>

      </div>
    )
  }

  return (
    <div className='iq__possibility section__padding' id='possibility'>
      <div className='iq__possibility-image'>
        <img src={possibilityImage} alt="possibility" />
      </div>
      <div className='iq__possibility-content'>
        <h4 onClick={props.handleRegClick}>Sign Up To Get Started</h4>
        <h1 className="gradient__text">Predictions Built From Historical Data!</h1>
        <p>Our machine learning model creates predictions by learning from thousands of fights,
          dating all the way back to 2003. Learning from the fight statistics and fighters to produce
          accurate percentage based predictions.</p>

        <h4 onClick={props.handleRegClick}>Sign Up To Get Started</h4>

      </div>

    </div>
  )

}

export default Possibility