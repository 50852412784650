import React from 'react';
import "./payPage.css";
import { useState, useEffect } from 'react';
import AuthService from '../../services/auth.service';
import logo from "../../assets/logo.png"
import StripePaymentInput from '../../components/subscription/StripePaymentInput';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeLifetimePayment from '../../components/subscription/StripeLifetimePayment';
import { useNavigate } from 'react-router-dom';



const oneYear = {
    duration: "365 Days",
    features: ["Fight Predictions from our Artifical intelligence model.",
        "Statistical fighter comparisons visualized in various chart forms.",
        "Access to any feature update while your subscription is active.",
        "Ability to request FightxIQ features & updates."],
    price: "420",
    amount: 42000,
    planName: "yearly",
    priceId: "price_1OeX7UCaIqAeeqFqaADfTLZs"
}
const oneMonth = {
    duration: "30 Days",
    features: ["Fight Predictions from our Artifical intelligence model.",
        "Statistical fighter comparisons visualized in various chart forms.",
        "Access to any feature update while your subscription is active."],
    price: "40",
    amount: 4000,
    planName: "monthly",
    priceId: "price_1OeX7UCaIqAeeqFqnVxCyBWc"
}
const lifetime = {
    duration: "Lifetime",
    features: ["Fight Predictions from our Artifical intelligence model.",
        "Statistical fighter comparisons visualized in various chart forms.",
        "Access to any feature update while your subscription is active.",
        "Ability to request FightxIQ features & updates.",
        "Premium LIFETIME access to FightxIQ."],
    price: "599",
    amount: 59900,
    planName: "lifetime",
    priceId: "price_1OeX7UCaIqAeeqFqiIAVaz8K"
}

const PayPage = (props) => {

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const user = AuthService.getCurrentUser();
    const [subscription, setSubscription] = useState(oneMonth);

    const navigation = useNavigate();
    useEffect(() => {
        if (props.subscriptionInfo.status === "active" || props.subscriptionInfo.status === "LIFETIME") {
            navigation('/');
            props.showToastMessage("You're already subscribed.")
        }

        if (props.currentUser === undefined) {
            navigation('/');
            props.showToastMessage("Please log in to subscribe.")
        }
    }, [props.subscriptionInfo]);


    useEffect(() => {

        AuthService.getPublicKey().then(async (r) => {
            const { publishableKey } = r.data;
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    useEffect(() => {

        AuthService.getClientSecret(subscription).then(async (r) => {
            var clientSecret = await r.data.clientSecret;
            setClientSecret(clientSecret);
        }).catch(async (e) => {
            props.showToastMessage("Failed to obtain load, please try again.");
            navigation("/account");
        })
    }, [subscription]);

    const subList = subscription.features.map((plan, index) => <li key={index} > {plan} <div className='list_border_div' /></li>)

    const handleClick = (selection) => {
        if (selection.target.value === "monthly") {
            setSubscription(oneMonth);

        } else if (selection.target.value === "yearly") {
            setSubscription(oneYear);
        } else if (selection.target.value === "lifetime") {
            setSubscription(lifetime);

        }
    };




    const appearance = {
        theme: 'night',
        labels: 'floating',
        logo: "",
        variables: {
            fontFamily: 'Sohne, system-ui, sans-serif',
            fontWeightNormal: '500',
            borderRadius: '6px',
            colorBackground: '#0A2540',
            colorPrimary: '#EFC078',
            accessibleColorOnColorPrimary: '#1A1B25',
            colorText: 'white',
            colorTextSecondary: 'white',
            colorTextPlaceholder: '#727F96',
            tabIconColor: 'white',
            logoColor: 'dark',
        }
    }

    if (clientSecret === "") {
        return (<div>LOADING</div>)
    }

    return (
        <div className='pay_page'>
            <h2>FightxIQ Premium Access</h2>
            <p>Gain full access to FightxIQ with a subscription plan that suites you. </p>
            <div className='pay_form'>



                <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>



                    <div className='checkout'>
                        <div className='billing_input'>
                            <img src={logo} alt='logo' />


                            <div className='pay_card_input'>
                                {subscription.duration !== "Lifetime"
                                    ? <StripePaymentInput
                                        subscriptionSelection={subscription}
                                        currentUser={user}
                                        setAlertMessage={props.setAlertMessage}
                                        setIsNewAlert={props.setIsNewAlert}
                                        showToastMessage={props.showToastMessage}

                                    />
                                    : <StripeLifetimePayment
                                        clientSecret={clientSecret}
                                        subscriptionSelection={subscription}
                                        currentUser={user}
                                        setAlertMessage={props.setAlertMessage}
                                        setIsNewAlert={props.setIsNewAlert}
                                        showToastMessage={props.showToastMessage} />
                                }
                            </div>

                        </div>


                        <div className='checkout_summary'>

                            <h1>Duration </h1>
                            <p>{subscription.duration} </p>

                            <div>
                                <h1>Features</h1>
                                <div className='subList_div'>
                                    <ul className='subList_ul'>
                                        {subList}
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <h1>Price </h1>
                                <p className='price'>
                                    ${subscription.price}
                                </p>



                            </div>

                            <div>

                                <select onChange={handleClick}>
                                    <option value="monthly" label='30 Days'></option>
                                    <option value="yearly" label='1 Year'></option>
                                    <option value="lifetime" label='Lifetime'></option>
                                </select>
                                <p style={{ fontStyle: "oblique", textDecoration: "none", textShadow: "none", color: "white", fontSize: 16, opacity: "55%", paddingTop: "8px" }}>Select your subscription plan.</p>
                            </div>
                        </div>

                    </div>


                </Elements>

            </div>
        </div>

    )
}

export default PayPage;