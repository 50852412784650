import React from 'react'
import "./PrivacyPolicyContent.css"
const PrivacyPolicyContent = () => {
    return (
        <div>
            <div className='tos-content_header'>
                <h2>
                    Privacy Policy for FightxIQ.com

                </h2>


                <div className='tos-content_info'>
                    <h3>Effective Date: October 11th, 2023</h3>

                </div>
            </div>


            <div className='tos-content_content'>
                <h3>Preliminary Provisions</h3>
                <h3>Preamble</h3>
                <p>The entity that owns and operates the website known as FightxIQ.com, hereinafter referred to as "We," "Our," "Us," or the "Company," is committed to safeguarding the privacy and personal data of its users ("User," "You," "Your"). This Privacy Policy ("Policy") is promulgated to provide a transparent and comprehensive explanation regarding the collection, processing, storage, and disclosure of personal data and information.</p>
                <h3>Acceptance of Terms</h3>
                <p>By accessing, browsing, and/or utilizing the services or functions offered on FightxIQ.com ("Website," "Service," or "Platform"), You signify Your irrevocable acceptance of the terms and conditions stipulated within this Policy. Should You disagree with or not accept any part of this Policy, You must discontinue Your use of the Website immediately.</p>
                <h3>Article 1: Information Collection and Data Processing</h3>
                <h3>Section 1.1: Collection of Personal Data</h3>
                <p>The sole category of personal data ("Personal Data") that We collect from You is your email address, which is mandatorily furnished by You during the process of account creation on the Platform. This data is collected for the explicit purpose of account identification, management, and provision of Our Services.</p>
                <h3>Section 1.2: Third-Party Payment Processors and Financial Data</h3>
                <p>For the facilitation of financial transactions, including but not limited to subscription payments, We utilize specialized third-party service providers ("Payment Processors"). We hereby declare that We neither collect nor store any form of financial or payment data from Users. Such data is directly provided by You to Our Payment Processors, and the collection, processing, and storage thereof are governed solely by the privacy policies and terms of service of the respective Payment Processors.</p>
                <h3>Section 1.3: Cookies and Data Tracking Technologies</h3>
                <p>We may employ cookies, pixels, beacons, and other similar tracking technologies ("Tracking Technologies") to collect aggregated data for statistical analyses and to improve the overall user experience on the Platform. This may include, but is not limited to, tracking page views, user pathways through the Platform, and other interactive behaviors. You retain the right to opt out of the use of Tracking Technologies through the settings of your web browser; however, it is to be noted that such an action may affect the functionality and user experience of the Platform.</p>
                <h3>Article 2: Information Use and Processing Objectives</h3>
                <h3>Section 2.1: Purpose and Rationale for Data Collection</h3>
                <p>Your email address is expressly harvested for a circumscribed and legitimate set of operational objectives, most notably for account identification, management, and the facilitation of the Services provided by the Platform. Such information serves as a unique identifier for Users and is integral for the internal administrative functions of Our Platform, including but not limited to account recovery, communications, and Service updates.</p>
                <h3>Section 2.2: Analytics and Improvement of Services</h3>
                <p>We may engage in the systematic compilation and analysis of anonymized or aggregated User data ("Analytical Data"). Such Analytical Data is used exclusively for the purpose of conducting statistical analyses aimed at the enhancement, optimization, and diversification of the Platform’s features, usability, and overall user experience. This may include monitoring traffic patterns, User behavior, and interaction with various elements of the Platform to identify areas for improvement or augmentation.</p>
                <h3>Article 3: Dissemination and Disclosure of Personal Data</h3>
                <h3>Section 3.1: General Principles on Information Sharing</h3>
                <p>The Company adheres to stringent protocols concerning the non-disclosure of your Personal Data. Under no circumstances do we engage in the selling, renting, leasing, or gratuitous dissemination of your Personal Data to third-party entities for commercial or marketing purposes.</p>
                <h3>Section 3.2: Legal Requirements and Exceptional Circumstances</h3>
                <p>Notwithstanding the aforementioned general principles, We reserve the right to disclose your Personal Data in specific instances mandated by applicable laws and governmental regulations. Such instances may include, but are not limited to, court orders, law enforcement requests, and other judicial or administrative procedures that compel the disclosure of Personal Data. In such cases, disclosure will be executed in full compliance with the relevant legal and regulatory stipulations.</p>
                <h3>Section 3.3: Privacy Policy Exceptions</h3>
                <p>Any form of information sharing outside of the provisions stipulated herein shall be explicitly articulated and outlined in this Privacy Policy or any subsequent amendments thereto.</p>
                <h3>Article 4: Data Security and Integrity Measures</h3>
                <h3>Section 4.1: Implementation of Security Protocols</h3>
                <p>The Company takes the security of your Personal Data with utmost seriousness and has implemented a variety of technological and procedural security measures ("Security Measures"). These Security Measures are designed to protect your Personal Data from unauthorized access, alteration, disclosure, and destruction. Such measures may include, but are not limited to, encryption technologies, secure socket layers (SSL), firewalls, and regular security audits.</p>
                <h3>Section 4.2: Limitation of Security Guarantees</h3>
                <p>Notwithstanding the above security protocols, it is expressly acknowledged that no method of electronic transmission over the Internet, or method of electronic storage, can be assured to be impenetrable or absolutely secure. As such, while We strive to use commercially acceptable means to protect your Personal Data, We cannot guarantee its absolute security.</p>
                <h3>Section 4.3: Breach Notification</h3>
                <p>In the unlikely event of a data breach that affects the security and integrity of your Personal Data, the Company commits to notify affected Users in accordance with applicable laws and regulations. Such notification shall include details concerning the nature of the breach, the data affected, the actions taken to mitigate the breach, and steps Users can take to further protect their information.</p>
                <h3>Article 5: Amendments and Policy Revisions</h3>
                <h3>Section 5.1: Policy Update and Amendment Procedures</h3>
                <p>We reserve the unilateral right to modify, alter, revise, or otherwise amend this Privacy Policy at our sole discretion. Any such modifications or changes will be effective upon their publication on this page or any other section within the Website as deemed appropriate by the Company.</p>
                <h3>Section 5.2: User Notification and Communication</h3>
                <p>While We may endeavor to notify Users of any changes to this Policy, it is the User's responsibility to regularly check this Privacy Policy for any updates. The date of the last modification will be prominently displayed at the top of this Privacy Policy document for ease of reference.</p>
                <h3>Section 5.3: Acceptance of Revised Policy</h3>
                <p>Your continued access to, interaction with, and/or use of the Website subsequent to any amendments or revisions to this Privacy Policy shall signify your irrevocable acceptance of such amended or revised terms. If you do not agree to any change in the Policy terms, your sole remedy is to discontinue your use of the Website and its Services.</p>
                <h3>Article 6: Contact Information for Inquiries and Grievances</h3>
                <h3>Section 6.1: Communication Channels</h3>
                <p>For any inquiries, questions, or concerns you may have pertaining to this Privacy Policy, or for the exercise of your rights described herein, you are encouraged to contact us through the prescribed communication channels as indicated below.</p>
                <h3>Section 6.2: Official Contact Information</h3>
                <p>To ensure that your queries or grievances are addressed in an expedient manner, please direct your communications to the following contact details: admin@fightxiq.com.</p>
                <h3>Section 6.3: Response Timeframe</h3>
                <p>We are committed to acknowledging and, where feasible, resolving all received inquiries or grievances within a reasonable timeframe, and in accordance with applicable laws and regulations.</p>
                <h3>Article 7: Governing Law and Jurisdictional Compliance</h3>
                <h3>Section 7.1: Governing Law</h3>
                <p>This Privacy Policy, and any dispute arising out of or in connection with it, shall be governed by, construed, and enforced in accordance with the laws of Texas, irrespective of its conflict of laws principles that may prescribe the application of the law of another jurisdiction.</p>
                <h3>Section 7.2: Jurisdictional Exclusivity</h3>
                <p>You hereby expressly consent to the exclusive jurisdiction and venue of the courts located within the territory of Texas, for all disputes and matters arising from or related to this Privacy Policy or your use of the Website.</p>
                <h3>Section 7.3: Conflict of Laws</h3>
                <p>To the extent that the law of another jurisdiction is found to apply, it shall not affect the enforceability, construction, interpretation, or validity of this Privacy Policy, which will remain governed by the laws of Texas.</p>

            </div >
        </div >
    )
}

export default PrivacyPolicyContent