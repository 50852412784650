import React from 'react';
import './App.css';
import { Possibility, WhatIq, Header, Features } from './containers';
import { Cta, Brand } from './components';
import Pricing from './containers/pricing/Pricing';
import Preview from './containers/preview/Preview';


const App = (props) => {


  if (props.loggedIn && (props.subscriptionInfo.status === "active" || props.subscriptionInfo.status === "LIFETIME")) {

    return (

      <div className='App'>

        <div className='gradient__bg'>


          <Header loggedIn={props.loggedIn} subscriptionStatus={props.subscriptionInfo.status} />

        </div>
        <Brand />
        <WhatIq />
        <Features loggedIn={props.loggedIn} subscriptionStatus={props.subscriptionInfo.status} />
        <Preview />

        <Possibility loggedIn={props.loggedIn} subscriptionStatus={props.subscriptionInfo.status} />

      </div>

    )
  }
  if (props.loggedIn && props.subscriptionInfo.status === "Inactive") {
    return (

      <div className='App'>

        <div className='gradient__bg'>


          <Header loggedIn={props.loggedIn} subscriptionStatus={props.subscriptionInfo.status} />

        </div>
        <Brand />
        <WhatIq />
        <Features loggedIn={props.loggedIn} subscriptionStatus={props.subscriptionInfo.status} />
        <Preview />
        <Pricing
          isShowRegister={props.isShowRegister}
          loggedIn={props.loggedIn}
          handleRegClick={props.handleRegClick} />
        <Possibility loggedIn={props.loggedIn} subscriptionStatus={props.subscriptionInfo.status} />

      </div>

    )
  }

  return (

    <div className='App'>

      <div className='gradient__bg'>


        <Header handleGetStartedEmailInput={props.handleGetStartedEmailInput} handleRegClick={props.handleRegClick} />

      </div>
      <Brand />
      <WhatIq />
      <Features handleRegClick={props.handleRegClick} />
      <Cta handleRegClick={props.handleRegClick} />
      <Preview />
      <Pricing loggedIn={props.loggedIn}
        handleRegClick={props.handleRegClick} />
      <Possibility handleRegClick={props.handleRegClick} />

    </div>

  )
}

export default App