import React, { useEffect, useState } from 'react';
import './predictions.css';
import '../../components/feature/feature.css';
import { useNavigate } from 'react-router-dom';
import AuthService from "../../services/auth.service";
import PredictionTabsOutput from '../../components/ChartTabs/Prediction_Tabs';
import authHeader from '../../services/auth-header';
import LoadingScreen from '../../components/loading/LoadingSreen';


const Output = (props) => {

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [chartIsLoading, setChartIsLoading] = useState(true);

  const [userReady, setUserReady] = useState(false);

  const [eventData, setEventData] = useState({});
  const [eventHistory, setEventHistory] = useState({});




  useEffect(() => {
    const user = AuthService.getCurrentUser("user");

    if (!user) {
      navigate("/");
      props.showToastMessage("Please Log in...", "error");
    } else {

      AuthService.isSubscriber(user.id, authHeader).then((data) => {
        setUserReady(true);
      }).catch((error) => {
        navigate("/");
        props.showToastMessage("Please subscribe for Predictions Dashboard access.", "error");

      })
    }







  }, [])



  useEffect(() => {
    if (userReady) {
      AuthService.UpcomingFightDataRetrieval(authHeader, props.showToastMessage).then((res) => res.data)
        .then((resJSON) => {

          let date_data = [];
          for (let i = 0; i < resJSON.length; i++) {
            let date = resJSON[i].event_date;
            if (!date_data.includes(date)) {
              date_data.push(date);
            }
          };



          const Event_One = resJSON.filter(({ event_date }) => event_date === date_data[0]);
          const Event_Two = resJSON.filter(({ event_date }) => event_date === date_data[1]);
          const Event_Three = resJSON.filter(({ event_date }) => event_date === date_data[2]);
          const Event_Four = resJSON.filter(({ event_date }) => event_date === date_data[3]);


          setEventData({
            eventOne: Event_One,
            eventTwo: Event_Two,
            eventThree: Event_Three,
            eventFour: Event_Four,
          });
          setLoading(false);
        }).catch((err) => {

          if (err.response.status === 403) {
            props.showToastMessage(err.response.data.message, "error");
            navigate("/");
          }

          if (err.response.status === 401) {

            props.showToastMessage(err.response.data.message + "Please contact us if the problem persists.", "error");
            navigate("/account");
          }
          if (err.response.status === 400) {

            props.showToastMessage(err.response.data.message + "Please contact us if the problem persists.", "error");
            navigate("/");

          }
        });
    }

  }, [userReady]);




  useEffect(() => {

    if (userReady) {
      AuthService.FighterHistory(authHeader, props.showToastMessage)
        .then((res) => res.data)
        .then((resJSON) => {

          const event_one_fight_history = resJSON.slice(0, eventData.eventOne.length);
          const event_two_fight_history = resJSON.slice(eventData.eventOne.length, eventData.eventOne.length + eventData.eventTwo.length + 1);
          const event_three_fight_history = resJSON.slice((eventData.eventOne.length + eventData.eventTwo.length + 1), (eventData.eventOne.length + eventData.eventTwo.length + eventData.eventThree.length));
          const event_four_fight_history = resJSON.slice((eventData.eventOne.length + eventData.eventTwo.length + eventData.eventThree.length), (eventData.eventOne.length + eventData.eventTwo.length + eventData.eventThree.length + eventData.eventFour.length));
          setEventHistory({
            eventOneHistory: event_one_fight_history,
            eventTwoHistory: event_two_fight_history,
            eventThreeHistory: event_three_fight_history,
            eventFourHistory: event_four_fight_history,

          });
          setChartIsLoading(false);

        }).catch((err) => {
          if (err.response.status === 403) {

            props.showToastMessage(err.response.data.message, "error");
            navigate("/");
          }

          if (err.response.status === 401) {

            props.showToastMessage(err.response.data.message + "Please contact us if the problem persists.", "error");
            navigate("/");
          }

          if (err.response.status === 400) {

            props.showToastMessage(err.response.data.message + "Please contact us if the problem persists.", "error");
            navigate("/");

          }



        });

    }
  }, [isLoading]);





  if (chartIsLoading || isLoading) {
    return <div style={{ justifySelf: "center", alignSelf: "center", margin: "auto" }}><LoadingScreen /></div>
  }

  if (props.subscriptionInfo.status === "Inactive") {
    navigate("/");
    props.showToastMessage("Please subscribe for Predictions Dashboard access", "error");
  }




  return (
    <div>

      <div className='iq__predictions'>
        <div className='iq__predictions-intro'>
          <h1>Predictions</h1>
          <p>Below is the percentage chance of each fighter winning. This prediction came from analyzing 20 years worth of data, learning from each stat and fight outcome.</p>
        </div>


        <div className='iq__predictions-container'>

          <PredictionTabsOutput
            fighterHistory={[eventHistory.eventOneHistory, eventHistory.eventTwoHistory, eventHistory.eventThreeHistory, eventHistory.eventFourHistory]}
            events={[eventData.eventOne, eventData.eventTwo, eventData.eventThree, eventData.eventFour]}
            eventNames={[eventData.eventOne[0].event_name, eventData.eventTwo[0].event_name, eventData.eventThree[0].event_name, eventData.eventFour[0].event_name]} />

        </div>

      </div>

    </div>
  )
}




export default Output;
