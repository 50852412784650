import React, { useState } from 'react';
import logo from "../../assets/logo.png";

import {
    useStripe,
    useElements,
    PaymentElement,
    AddressElement,
} from "@stripe/react-stripe-js"
import authService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';

import "./billing_change.css"


const BillingChangeForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [billingAddress, setBillingAddress] = useState({});
    const [billingName, setBillingName] = useState({});
    const navigation = useNavigate();

    const handleError = (error) => {
        props.showToastMessage(error.message);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {

            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }


        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            clientSecret: props.clientSecret,


            confirmParams: {
                return_url: 'https://fightxiq.com/account',


            },

            redirect: "if_required"
        });


        if (error) {
            // This point is only reached if there's an immediate error when confirming the Intent.
            // Show the error to your customer (for example, "payment details incomplete").
            handleError(error);
        } else {

            const billingData = await authService.changeBilling(
                props.user,
                billingAddress,
                billingName,
                setupIntent.payment_method);

            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate

            navigation("/account");
            navigation(0);

        }

    };



    return (
        <div>
            <form onSubmit={(e) => handleSubmit(e)} className='pay_form'>


                <div className='checkout'>
                    <div className='billing_input'>
                        <img src={logo} alt='logo' />

                        <AddressElement onChange={(event) => {
                            if (event.complete) {
                                // Extract potentially complete address
                                setBillingAddress(event.value.address);
                                setBillingName(event.value.name);
                            }
                        }} options={{
                            mode: "billing",


                        }} />

                        <PaymentElement options={{
                            paymentMethodOrder: ['google_pay', 'card'],
                            business: { name: "FightxIQ" },

                        }} />
                        <div style={{ alignItems: "center", alignContent: "center", margin: "auto", paddingTop: "1.5rem" }}>
                            <button className='billing_update-button' style={{ color: "#fff", backgroundColor: "#FF4820", margin: "auto" }} disabled={!stripe}>Update Billing</button>
                        </div>



                    </div>

                </div>


            </form ></div>
    )
}

export default BillingChangeForm