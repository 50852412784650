import React from 'react';
import PricingComp from '../../components/pricing/PricingComp';
import "./pricing.css"
import { Link } from 'react-router-dom';


const Pricing = (props) => {
    const oneYear = {
        duration: "365 Days",
        features: ["Fight Predictions from our personal Artifical intelligence model.",
            "Stat comparison from visualized fighter data.",
            "Access to any feature update while subscription is active.",
            "Ability to request FightxIQ feature updates."],
        price: "420",
        planName: "yearly",
        planVariationId: "NW7FDOE3XQPZLVS5DVKPNCBT"
    }
    const oneMonth = {
        duration: "30 Days",
        features: ["Fight Predictions from our personal Artifical intelligence model.",
            "Stat comparison from visualized fighter data.",
            "Access to any feature update while subscription is active."],
        price: "40",
        planName: "monthly",
        planVariationId: "Z5EJG3OTOWTTJXS6MNVXAYZ2"
    }
    const lifetime = {
        duration: "Lifetime",
        features: ["Fight Predictions from our personal Artifical intelligence model.",
            "Stat comparison from visualized fighter data.",
            "Access to any feature update while subscription is active.",
            "Ability to request FightxIQ feature updates.",
            "Premium LIFETIME access to FightxIQ.",
            "For the first 50 customers ONLY."],
        price: "599",
        planName: "lifetime",
    }

    const handleRegisterClick = () => {
        props.handleRegClick();

    };

    return (
        <div className='pricing_container'>
            <h1 className='pricing-header'>Pricing</h1>
            <div className='pricing-comp_container'>
                <div className='pricing_section'>
                    <PricingComp
                        currentUser={props.currentUser}
                        plan={oneMonth}

                    />
                    {props.loggedIn
                        ? <Link className='pricing_link' to={"/payment"}><p className='signup-link'>Subscribe for access</p></Link>
                        : <p onClick={handleRegisterClick} className='signup-link'>Sign Up To Get Started</p>
                    }

                </div>
                <div className='pricing_section'>
                    <PricingComp
                        currentUser={props.currentUser}
                        plan={lifetime}

                    />
                    {props.loggedIn
                        ? <Link className='pricing_link' to={"/payment"}><button className='pricing_button'>Subscribe for access</button></Link>
                        : <button type='button' onClick={handleRegisterClick} className='pricing_button'>Sign Up</button>
                    }


                </div>
                <div className='pricing_section'>
                    <PricingComp
                        currentUser={props.currentUser}
                        plan={oneYear}

                    />
                    {props.loggedIn
                        ? <Link className='pricing_link' to={"/payment"}><p className='signup-link'>Subscribe for access</p></Link>
                        : <p onClick={handleRegisterClick} className='signup-link'>Sign Up To Get Started</p>
                    }

                </div>
            </div>
        </div>
    )
}

export default Pricing;