import React from 'react'
import "./pricing_comp.css"
const PricingComp = (props) => {

    return (

        <div className='pricing-comp'>

            <header>

                {props.plan.planName === "lifetime" ? <p>SPECIAL OFFER <br />For the first 50 customers ONLY.</p> : <p></p>}
                <p className='comp_title'>{props.plan.duration}</p>

                <h1 className='comp_price pricing_gradient__text'>${props.plan.price}</h1>

            </header>
            <div className='comp_feature'>
                <div className='comp_features'>
                    {props.plan.features.map((item) => (

                        <li className='pricing-container__feature-list'>

                            <div className='pricing-container__feature-bullet'></div>
                            {item}

                        </li>
                    ))}

                </div>

            </div>

        </div >
    )
}

export default PricingComp;