import React, { useState, useEffect } from 'react';
import AuthService from '../../services/auth.service';
import "./billing_change.css"
import {
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BillingChangeForm from './BillingChangeForm';
import { useNavigate } from 'react-router-dom';

const BillingChange = (props) => {

    const user = AuthService.getCurrentUser();
    const [clientLoaded, setClientLoaded] = useState(false);

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const navigation = useNavigate();

    const options = {
        mode: 'setup',


        setup_future_usage
            : 'off_session',
        // Fully customizable with appearance API.

    };
    const appearance = {
        theme: 'night',
        labels: 'floating',
        logo: "",
        variables: {
            fontFamily: 'Sohne, system-ui, sans-serif',
            fontWeightNormal: '500',
            borderRadius: '6px',
            colorBackground: '#0A2540',
            colorPrimary: '#EFC078',
            accessibleColorOnColorPrimary: '#1A1B25',
            colorText: 'white',
            colorTextSecondary: 'white',
            colorTextPlaceholder: '#727F96',
            tabIconColor: 'white',
            logoColor: 'dark',
        }
    }

    useEffect(() => {

        if (props.currentUser === undefined) {
            navigation("/");
            props.showToastMessage("No need to visit here without being logged in. Please log in for access.")
        } else {

            setClientLoaded(true);
            AuthService.getClientSecretSetup(user).then(async (r) => {
                const clientSecret = await r.data.clientSecret;

                setClientSecret(clientSecret);
                setClientLoaded(false);
            }).catch(e => {
                setClientLoaded(false);
            })
        }
    }, []);

    useEffect(() => {

        AuthService.getPublicKey().then(async (r) => {
            const { publishableKey } = r.data;
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);



    if (clientLoaded) return (<div></div>)
    return (
        <div className='pay_page'>

            <h2>FightxIQ Premium Access</h2>
            <p>Gain full access to FightxIQ with a subscription plan that suites you. </p>

            <Elements stripe={stripePromise} options={{ clientSecret: clientSecret, options, appearance }}>

                <BillingChangeForm
                    clientSecret={clientSecret}
                    user={user}
                    showToastMessage={props.showToastMessage}
                />
            </Elements>

        </div >
    )
}

export default BillingChange