import React from 'react';
import "./TermsOfServiceContent.css";

const TermsOfServiceContent = () => {
    return (
        <div>
            <div className='tos-content_header'>
                <h2>
                    Terms of Service ("Agreement")
                </h2>


                <div className='tos-content_info'>
                    <h3>Effective Date: October 11th, 2023</h3>
                    <h3>Website: FightxIQ.com</h3>
                    <h3>Operated by: FightxIQ LLC</h3 >
                </div>
            </div>


            <div className='tos-content_content'>

                <h3>Introduction and Acceptance of Terms</h3 >
                <p>
                    Welcome to FightxIQ.com (hereinafter referred to as the "Website," "Site," "We," "Us," or "Our"). The Website is owned and operated by FightxIQ LLC ("Company," "We," "Us," or "Our"). The Site is designed to offer machine learning-based analytical predictions related to UFC (Ultimate Fighting Championship) fights, functioning as an informational utility for users wishing to gain insights into various aspects of fighter performance (the "Services").
                    Please read these Terms of Service ("Terms," "Agreement") carefully before using the Website operated by FightxIQ LLC. This Agreement sets forth the legally binding terms and conditions for your use of the Website at FightxIQ.com.
                    By accessing or using the Website in any manner, including, but not limited to, visiting or browsing the Website or contributing content or other materials to the Website, you agree to be bound by these Terms of Service. If you disagree with any part of the terms then you may not access the Website.
                </p>

                <h3>Article 1: Disclaimer of Warranties and Limitation of Liability</h3>


                <h3>1.1 Nature of Predictions</h3>
                <p>
                    The Website, FightxIQ.com, provides statistical predictions concerning the outcomes of Ultimate Fighting Championship (UFC) matches ("Predictive Data"). These predictions are generated by machine learning algorithms that process and analyze historical and statistical data. The Predictive Data is intended solely for informational and entertainment purposes.
                </p>
                <h3>1.2 No Guarantees or Warranties</h3>
                <p>The Company expressly disclaims all warranties, either express or implied, regarding the accuracy, timeliness, reliability, completeness, or suitability of the Predictive Data for any particular purpose. Users acknowledge and agree that the Predictive Data should not be considered as professional betting advice and should not be relied upon for the purposes of placing bets or making other decisions.</p>
                <h3>1.3 No Liability for Decisions Made</h3>
                <p>The Company shall not be responsible or liable, directly or indirectly, for any damage or loss incurred by any user in connection with bets or decisions made based on the Predictive Data. Users agree that they make any and all betting and related decisions entirely at their own risk and that they assume full responsibility for all outcomes.</p>
                <h3>1.4 Limitation of Liability</h3>
                <p>To the fullest extent permissible by applicable law, FightxIQ.com and its owners, operators, affiliates, licensors, and service providers disclaim all liability, whether based in contract, tort (including negligence), strict liability, or otherwise, and shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with access to or use of the Website, even if FightxIQ.com has been advised of the possibility of such damages.</p>
                <h3>Article 2: Data Collection and Privacy</h3>
                <h3>2.1 Personal Data Collection</h3>
                <p>The Website, FightxIQ.com ("We," "Our," "Us"), collects certain personal data to enable user registration and ensure a tailored user experience. The only personally identifiable information ("Personal Data") collected during the account sign-up process is the user's email address. No other Personal Data is collected directly by Us.</p>
                <h3>2.2 Payment Data and Third-Party Processing</h3>
                <p>For the purposes of conducting transactions, including but not limited to payment processing, We utilize third-party service providers ("Payment Processors"). All Payment Processors are governed by their respective privacy policies, and any financial data, such as credit card numbers, provided by users for payment processing is not collected or stored by Us. Users are encouraged to read the privacy policies of these third-party providers before completing any transactions.</p>
                <h3>2.3 Data Security</h3>
                <p>While We strive to use commercially acceptable means to protect the Personal Data of users, We make no guarantees as to the security of any information transmitted to or from the Website.</p>
                <h3>2.4 Compliance with Privacy Legislation</h3>
                <p>We endeavor to comply with all applicable privacy legislation and regulations. By using this Website, you consent to the collection, storage, and use of your Personal Data as described in this Agreement and in Our Privacy Policy, which is incorporated herein by reference.</p>
                <h3>Article 3: Intellectual Property Rights</h3>
                <h3>3.1 Ownership of Intellectual Property</h3>
                <p>All text, graphics, editorial content, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software, videos, typefaces, and other content (collectively "Proprietary Material") that users see or read on the Website is owned by FightxIQ LLC, or is used with permission. This Proprietary Material is protected in all forms, media, and technologies now known or hereinafter developed. FightxIQ LLC owns the copyright, trademark, service mark, patent, and/or other intellectual property rights (collectively, "Intellectual Property Rights") associated with the Proprietary Material, unless otherwise indicated.</p>
                <h3>3.2 Data and Algorithms</h3>
                <p>The algorithms, predictive models, and data analyses, as well as all related methodologies, frameworks, and outputs, including, but not limited to, the Predictive Data provided by the Website ("Algorithms and Data"), are Proprietary Material owned by FightxIQ LLC. These Algorithms and Data are protected by intellectual property laws, including but not limited to, copyright, trademark, and trade secret laws, as applicable.</p>
                <h3>3.3 Prohibited Uses</h3>
                <p>No Proprietary Material may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, except with the prior written consent of FightxIQ LLC, or as specifically permitted by the features of the Website. Modification of the Proprietary Material or use of the Proprietary Material for any other purpose is a violation of the Intellectual Property Rights owned by FightxIQ LLC and may subject the offender to legal action.</p>
                <h3>3.4 Legal Remedies</h3>
                <p>Unauthorized use of the Proprietary Material or infringement upon Intellectual Property Rights may give rise to a claim for damages and/or be a criminal offense, subject to enforcement by FightxIQ LLC to the fullest extent of applicable law.</p>
                <h3>Article 4: Amendments to the Agreement</h3>
                <h3>4.1 Right to Amend</h3>
                <p>FightxIQ.com ("We," "Our," "Us") reserves the unilateral right to modify, amend, or change this Agreement (the "Terms") at any time and at Our sole discretion.</p>
                <h3>4.2 Notice of Amendments</h3>
                <p>Any such modifications, amendments, or changes will be posted on this page or a similar section of Our Website. We may, at Our discretion, also notify registered users via email or other communication methods. However, it is your responsibility to regularly review these Terms for any changes.</p>
                <h3>4.3 Acceptance of Amendments</h3>
                <p>Your continued access to or use of the Website following the posting of any modifications, amendments, or changes to the Terms constitutes your irrevocable acceptance of such modifications, amendments, or changes. If you do not agree to abide by these or any future Terms, do not use or access (or continue to use or access) the Website.</p>
                <h3>4.4 Rejection of Amendments</h3>
                <p>If any amendment is unacceptable to you, your only recourse is to cease using the Website. Your continued use of the Website following Our posting of amended Terms will constitute binding acceptance of the Agreement and its amendments.</p>
                <h3>Article 5: User Conduct</h3>
                <h3>5.1 General Conduct</h3>
                <p>Users agree not to use the Website or its Services in a manner that is illegal, harmful, or interferes with the operation of the Website or the experience of other users.</p>
                <h3>5.2 Prohibited Activities</h3>
                <p>Users are specifically prohibited from: (a) accessing data not intended for such user or logging onto a server or an account which the user is not authorized to access; (b) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; (c) using the Website or its Services for any purpose that is illegal or prohibited by these Terms.</p>

                <h3>Article 6: Termination</h3>
                <h3>6.1 Termination by Us</h3>
                <p>We reserve the right to suspend or terminate your account and prevent access to the Website for any reason, at our discretion.</p>
                < h3 > 6.2 Termination by You</h3 >
                <p>You can terminate your account at any time by contacting customer support. Upon termination, you will cease all use of the Website and any content, data, or features therein.</p>
                < h3> Article 7: Governing Law and Jurisdiction</h3 >
                <p>These Terms shall be governed by the laws of the State of Texas belonging to the United States of America, without respect to its conflict of laws principles.</p>
                < h3>Article 8: Dispute Resolution</h3 >
                <h3>8.1 Arbitration</h3>
                <p>Any dispute arising from these Terms or your use of the Website will be settled through binding arbitration in accordance with the rules of the American Arbitration Association.</p>
                <h3>8.2 Waiver of Jury Trial</h3>
                <p>You acknowledge and agree that you are waiving the right to a trial by jury as to all arbitrable disputes.</p>
                < h3 > 8.3 Waiver of Class Action</h3>
                <p>You further agree that any arbitration will be conducted in your individual capacity only and not as a class action or other representative action.</p>
                < h3 > Article 9: Severability</h3>
                <p>If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
                < h3 >Article 10: Entire Agreement</h3>
                <p>These Terms, along with any additional terms or policies incorporated herein by reference, represent the entire Agreement between you and FightxIQ LLC and supersede all prior agreements, communications, and proposals.</p>
                < h3 > Article 11: Contact Information and Communication</h3>
                < h3 > 11.1 General Inquiries</h3>
                <p>For any questions, clarifications, or general inquiries regarding these Terms of Service or the Website("Terms"), users should direct their communications to the following contact information: admin@fightxiq.com.</p>
                < h3 > 11.2 Official Communication</h3>
                <p>All official communications from the user to FightxIQ LLC should be made through the contact channels designated in Section 11.1.FightxIQ LLC disclaims all liability for any communications or inquiries directed to unverified or unauthorized contact points.</p>
                < h3 > 11.3 Response Time</h3>
                <p>While FightxIQ LLC endeavors to respond to all inquiries in a timely manner, we make no guarantees concerning the time required to address or resolve any questions, concerns, or disputes raised by users.</p>
                < h3 > 11.4 Method of Contact</h3 >
                <p>Inquiries may be submitted through any contact methods provided, including but not limited to, email, as specified in Section 11.1.</p>
                < h3 > 11.5 Legal Notices</h3 >
                <p>All legal notices or demands to or upon FightxIQ LLC shall be made in writing and shall be validly given to us only if sent to the contact information detailed in Section 11.1, unless otherwise stipulated by legal requirements.</p>

            </div >
        </div >
    )
}

export default TermsOfServiceContent
