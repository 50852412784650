import React from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Cell, LabelList, ResponsiveContainer } from 'recharts';
import { GiSlashedShield, GiBullseye, GiPunch } from "react-icons/gi"
import "../chart_styling.css"
import "../../chart_tooltip_styling.css";

const TakedownAccuracyDefenseChart = (props) => {

    const data = [
        {

            rName: props.data[props.index].r_name,
            bName: props.data[props.index].b_name,

            stat: "Takedown Accuracy",

            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",

            rData: ~~(props.data[props.index].r_career_takedown_accuracy * 100),
            bData: ~~(props.data[props.index].b_career_takedown_accuracy * 100),
        },
        {
            rName: props.data[props.index].r_name,
            bName: props.data[props.index].b_name,

            stat: "Takedown Defense",

            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",

            rData: ~~(props.data[props.index].r_career_takedown_defense * 100),
            bData: ~~(props.data[props.index].b_career_takedown_defense * 100)
        }
    ]

    return (
        <ResponsiveContainer width={"99%"} height={"100%"}>
            <BarChart
                data={data}
                width={700}
                height={500}
                barCategoryGap={10}
                barGap={5}
                margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="stat" xAxisId={"red"} tickLine={false} tick={customizedLabelXAxis} interval={0}>

                </XAxis>

                <YAxis unit={"%"} domain={[0, dataMax => (~~dataMax + 10)]} />
                <Tooltip content={<CustomeToolTip />} cursor={{ fill: "white", opacity: "15%" }} />
                <Legend content={<CustomLegend />} xAxisId={"red"} align='center' verticalAlign="top" />

                <Bar dataKey={"rData"} xAxisId='red' icon={<GiPunch />} name={data[0].rName} color="#FF5858" >
                    <LabelList content={<CustomizedLabelBar dataKey={"TakedownAcc"} />} />

                    {


                        data.map((entry, index) => (

                            <Cell key={`cell-${index}`} dataKey="TakedownAcc" fill={entry.rColorIndex} fillOpacity={"55%"} stroke={entry.rColorIndex} strokeLinejoin='round' strokeWidth={3.5} >

                            </Cell>

                        ))
                    }
                </Bar>
                <Bar dataKey={"bData"} xAxisId='red' icon={<GiPunch />} name={data[0].bName} color={"#4c9cf8"}>
                    <LabelList content={<CustomizedLabelBar dataKey={"TakedownDef"} />} />

                    {
                        data.map((entry, index) => (

                            < Cell key={`cell-${index}`} dataKey="TakedownDef" fill={entry.bColorIndex} fillOpacity={"55%"} stroke={entry.bColorIndex} strokeLinejoin='round' strokeWidth={3.5} />
                        ))

                    }
                </Bar>
            </BarChart >

        </ResponsiveContainer>
    );
}
const CustomLegend = (props) => {
    const payload = props.payload;
    return (
        <g className='AD_legend'>
            {
                payload.map((entry, index) => (

                    <div key={`item-${index}`}>
                        <span style={{ color: entry.payload.color, paddingRight: "4px" }}>{entry.payload.icon}</span>
                        <span style={{ color: entry.payload.color }} >{entry.value}</span>
                    </div>

                ))

            }
        </g>

    )
};
const CustomeToolTip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
        return (
            <div className='custom-tooltip' style={{ boxShadow: payload[0].payload.stat === "Takedown Accuracy" ? "0px 0px 9px white" : "0px 0px 9px #f39c12" }}>


                <div className='multi-stat_header'>

                    <h3 >
                        <div className='custom-tooltip_icon'>
                            {payload[0].payload.stat === "Takedown Accuracy" ? <GiBullseye size={40} color={"white"} /> : <GiSlashedShield size={40} color={"#f39c12"} />}

                        </div>
                        {payload[0].payload.stat}
                    </h3>

                </div>

                <div className='custom-toolip_multi-div'>
                    <div className='custom-toolip_multi-unit'>
                        <b style={{ color: payload[0].payload.rColorIndex }}>{payload[0].name}</b>
                        <p > <b>{payload[0].value}%</b> </p>

                    </div>


                    <div className='custom-toolip_multi-unit'>
                        <b style={{ color: payload[1].payload.bColorIndex }}>{payload[1].name}</b>
                        <p > <b>{payload[1].value}%</b></p>
                    </div>
                </div>





            </div>
        );
    }
    return null;
};
const CustomizedLabelBar = (props) => {
    const { x, y, value, index } = props;
    const payload = props;
    return (<g >


        {index === 0 ? <GiBullseye className='bar_icon' x={x + 2} y={y + 2} style={{ fill: index === 0 ? "white" : "#ffd700", stroke: "black", strokeWidth: 12 }} /> : <GiSlashedShield className='bar_icon' x={x + 2} y={y + 2} style={{ fill: index === 1 ? "#f39c12" : "white", stroke: "black", strokeWidth: 6 }} />}

        <text className='strikes_LA_bar-value' x={x} y={y - 5} fontSize={30} style={{ fill: payload.fill, stroke: "white" }} >
            {value}%</text>









    </g>
    );
}


const customizedLabelXAxis = (props) => {
    const { index, x, y, payload } = props;

    return (

        <g className='custom_XAxis-Label_LA-TD' transform={`translate(${x - 10},${y})`} >



            <text x={7} y={0} dy={16} textAnchor="middle" fill={"grey"}  >

                {payload.value}
            </text>
            <g transform={`translate(${-80},${1})`}  > {payload.value === "Takedown Defense" ? <GiSlashedShield color={"#ffd700"} /> : <GiBullseye color={"white"} />}</g>

        </g >


    );

};
export { TakedownAccuracyDefenseChart };