import React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Cell, LabelList, ResponsiveContainer } from 'recharts'
import { GiImpactPoint, GiGooeyImpact, GiPunch } from "react-icons/gi"
import "../chart_styling.css"
import "../../chart_tooltip_styling.css";

const StrikingLandedAbsorbedPM = (props) => {
    const data = [

        {
            rName: props.data[props.index].r_name,
            bName: props.data[props.index].b_name,

            stat: "Str. Landed Per Minute",
            rData: props.data[props.index].r_career_significant_strikes_landed_pm,
            bData: props.data[props.index].b_career_significant_strikes_landed_pm,
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8"

        },
        {
            rName: props.data[props.index].r_name,
            bName: props.data[props.index].b_name,
            stat: "Str. Absorbed Per Minute",
            rData: props.data[props.index].r_career_significant_strikes_absorbed_pm,
            bData: props.data[props.index].b_career_significant_strikes_absorbed_pm,
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8"
        }
    ];


    return (
        <ResponsiveContainer width={"99%"} height={"100%"}>
            <BarChart
                data={data}

                barCategoryGap={10}
                barGap={5}
                margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="stat" xAxisId={"red"} tickLine={true} tick={customizedLabelXAxis} interval={0} >
                </XAxis>

                <YAxis domain={[0, dataMax => (~~dataMax + 2)]} />
                <Tooltip content={<CustomeToolTip />} cursor={{ fill: "white", opacity: "15%" }} />
                <Legend content={<CustomLegend />} xAxisId={"red"} align='center' verticalAlign='top' />

                <Bar dataKey={"rData"} xAxisId='red' icon={<GiPunch />} name={data[0].rName} color={data[0].rColorIndex} >
                    <LabelList content={<CustomizedLabelBar dataKey={"StrikeLanded"} />} />

                    {


                        data.map((entry, index) => (

                            <Cell key={`cell-${index}`} dataKey="StrikeLanded" fill={entry.rColorIndex} fillOpacity={"55%"} stroke={entry.rColorIndex} strokeLinejoin='round' strokeWidth={3.5} >

                            </Cell>

                        ))
                    }  </Bar>
                <Bar dataKey={"bData"} xAxisId='red' icon={<GiPunch />} name={data[0].bName} color={data[0].bColorIndex}>
                    <LabelList content={<CustomizedLabelBar dataKey={"StrikeAbsorbed"} />} />

                    {
                        data.map((entry, index) => (

                            < Cell key={`cell-${index}`} dataKey="StrikeAbsorbed" fill={entry.bColorIndex} fillOpacity={"55%"} stroke={entry.bColorIndex} strokeLinejoin='round' strokeWidth={3.5} />
                        ))

                    }  </Bar>
            </BarChart >
        </ResponsiveContainer>
    )
};
const CustomLegend = (props) => {
    const payload = props.payload;
    return (
        <span className='AD_legend'>
            {
                payload.map((entry, index) => (

                    <div key={`item-${index}`}>
                        <span style={{ color: entry.payload.color, paddingRight: "4px" }}>{entry.payload.icon}</span>
                        <span style={{ color: entry.payload.color }} >{entry.value}</span>
                    </div>

                ))

            }
        </span>

    )
};
const CustomeToolTip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
        return (
            <div className='custom-tooltip' style={{ boxShadow: payload[0].payload.stat === "Str. Landed Per Minute" ? "0px 0px 9px white" : "0px 0px 9px #f39c12" }}>


                <div className='multi-stat_header'>

                    <h3 >


                        <div className='custom-tooltip_icon'>
                            {payload[0].payload.stat === "Str. Landed Per Minute" ? <GiGooeyImpact size={40} color={"white"} /> : <GiImpactPoint size={40} color={"#f39c12"} />}

                        </div>
                        {payload[0].payload.stat}
                    </h3>

                </div>

                <div className='custom-toolip_multi-div'>
                    <div className='custom-toolip_multi-unit'>
                        <b style={{ color: payload[0].payload.rColorIndex }}>{payload[0].name}</b>
                        <p > <b>{payload[0].value}</b> LPM</p>

                    </div>


                    <div className='custom-toolip_multi-unit'>
                        <b style={{ color: payload[1].payload.bColorIndex }}>{payload[1].name}</b>
                        <p > <b>{payload[1].value}</b> APM</p>
                    </div>
                </div>





            </div>
        );
    }
    return null;
};
const CustomizedLabelBar = (props) => {
    const { x, y, width, value, index } = props;
    const payload = props;
    return (<g >


        {index === 0 ? <GiGooeyImpact className='bar_icon' x={x + 5} y={y + 5} style={{ fill: index === 0 ? "white" : "#ffd700", stroke: "black", strokeWidth: 12 }} /> : <GiImpactPoint className='bar_icon' x={x + 5} y={y + 5} style={{ fill: index === 1 ? "#f39c12" : "white", stroke: "black", strokeWidth: 8 }} />}

        <text className='strikes_LA_bar-value' x={x} y={y - 5} fontSize={30} style={{ fill: payload.fill, stroke: "white" }} >
            {value}

        </text>




        <text className='strikes_LA_bar-unit' x={x + width / 1.5} y={y - 5} fontSize={20} style={{ fill: payload.fill, stroke: "black", outlineWidth: 2, strokeWidth: .75 }} >

            {index === 0 ? " LPM " : " APM"}


        </text>



    </g>
    );
}

const customizedLabelXAxis = (props) => {
    const { x, y, payload } = props;

    return (

        <g className='custom_XAxis-Label_PM' transform={`translate(${x},${y})`} >



            <text x={0} y={0} dy={16} textAnchor="middle" fill={"grey"}  >

                {payload.value}

            </text>


            <g transform={`translate(${-98},${3})`}> {payload.value === "Str. Landed Per Minute" ? <GiGooeyImpact transform={`translate(${10},${0})`} color={"white"} /> : <GiImpactPoint color={"#ffd700"} />}</g>

        </g >


    );

};



export { StrikingLandedAbsorbedPM };