import React, { useState } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import "./prediction_chart.css";



const DoughnutChart = (props) => {


    const data = [{
        "corner": "Red Corner", "percentage": (props.data[props.index].r_probability * 100),
        "fighter_name": props.data[props.index].b_name,
        "shadow": "2px 1px 1px #ff0000"

    }, {
        "corner": "Blue Corner", "percentage": (props.data[props.index].b_probability * 100),
        "fighter_name": props.data[props.index].r_name,

        "shadow": "2px 1px 1px #5aa4f8"

    }];
    const colors = ['rgb(231,60,78)', 'rgba(54, 162, 235)'];
    const [activeIndex, setActiveIndex] = useState(0);
    const [pieRes, setPieRes] = useState();
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
        props.setPieIndex(index);

    };
    const pieResize = (props) => {
        let res = (props / 10)
        setPieRes(res);
    }

    return (


        <div className="pie_div" >



            <ResponsiveContainer width={"99%"} height={"100%"} onResize={pieResize}>

                <PieChart margin={{ bottom: 0, left: 0, right: 0, top: 0 }}>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        innerRadius={pieRes * 1.20}
                        outerRadius={pieRes * 2.5}
                        fill="#8884d8"

                        paddingAngle={0}
                        dataKey="percentage"
                        onMouseEnter={onPieEnter}

                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`} stroke="black" fillOpacity={"55%"} fill={colors[index % colors.length]}
                            />
                        ))}



                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>


    );
}
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const headerShadow = props.name == 0 ? "2px 1px 1px #ff0000" : "2px 1px 1px #5aa4f8";
    var fighterStatOffset = props.name == 0 ? 32 : 22;




    return (

        <g className="all_chart">
            <text x={cx} y={cy} dy={-4} textAnchor="middle" className="pie_center-header" fill={"#FFF"} style={{ "textShadow": headerShadow }}>
                Win Chance


            </text>
            <text x={cx} y={cy} dy={40} textAnchor="middle" className="pie_center-text" fill={fill}>

                {`${(percent * 100).toFixed(2)}%`}


            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 4}
                outerRadius={outerRadius + 12}
                fill={fill}

            />

        </g >
    );
};

export { DoughnutChart };
