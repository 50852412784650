import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import authService from '../../services/auth.service';
import authHeader from '../../services/auth-header';
import { useNavigate } from 'react-router-dom';


const StripeLifetimePayment = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [billingAddress, setBillingAddress] = useState({});
    const [billingName, setBillingName] = useState({});
    const navigation = useNavigate();
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const handleError = (error) => {
        props.showToastMessage(error.message);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {

            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);

        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }


        //const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;


        // Confirm the Intent using the details collected by the Payment Element
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            clientSecret: props.clientSecret,
            confirmParams: {
                return_url: 'https://fightxiq.com/account',
                receipt_email: props.currentUser.email,

            },
            redirect: "if_required"
        });


        if (error) {
            // This point is only reached if there's an immediate error when confirming the Intent.
            // Show the error to your customer (for example, "payment details incomplete").
            handleError(error);
        } else {
            const subscription = await authService.subscribeLifetime(
                paymentIntent.id,
                props.subscriptionSelection,
                billingAddress,
                billingName,
                props.currentUser,
                authHeader)
                .then((payment) => {
                    navigation("/account");
                    navigation(0);

                    props.showToastMessage("Welcome to the family! We truly appreciate your support...");
                    sleep(3000);

                }).catch((error) => {
                    navigation("/account");
                    props.showToastMessage("Something went wrong...")
                })



        }
        setIsProcessing(false);



    }


    return (
        <div>
            <form onSubmit={(e) => handleSubmit(e)}>

                <AddressElement onChange={(event) => {
                    if (event.complete) {
                        // Extract potentially complete address
                        setBillingAddress(event.value.address);
                        setBillingName(event.value.name);
                    }
                }} options={{
                    mode: "billing",


                }} />

                <PaymentElement options={{
                    business: { name: "FightxIQ" },

                }} />
                <div style={{ alignItems: "center", alignContent: "center", margin: "auto", paddingTop: "1.5rem" }}>
                    <button disabled={isProcessing || !stripe} style={{ color: "#fff", backgroundColor: "#FF4820", margin: "auto" }} >Subscribe</button>
                </div>
            </form>

        </div>
    )
}

export default StripeLifetimePayment