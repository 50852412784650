import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, LabelList } from 'recharts';
import { ResponsiveContainer } from "recharts";

import { GiPunch, GiSandsOfTime } from "react-icons/gi";
import "./chart_styling.css";
import "../chart_tooltip_styling.css";



const AvgFightTimeChart = (props) => {


    const rFightTime = `${Math.floor(props.data[props.index].r_avg_fight_time / 60)}:${Math.round((props.data[props.index].r_avg_fight_time % 60))}`

    const bFightTime = `${Math.floor(props.data[props.index].b_avg_fight_time / 60)}:${Math.round((props.data[props.index].b_avg_fight_time % 60))}`
    const rFightTimeRounds = (Math.floor(props.data[props.index].r_avg_fight_time / 60) / 5);
    const bFightTimeRounds = (Math.floor(props.data[props.index].b_avg_fight_time / 60) / 5);

    const data = [
        {
            bName: props.data[props.index].b_name,

            rName: props.data[props.index].r_name,
            stat: "Average Fight Time",
            rData: props.data[props.index].r_avg_fight_time,
            bData: props.data[props.index].b_avg_fight_time,
            rFightTime: rFightTime,
            bFightTime: bFightTime,
            rFightTimeRounds: rFightTimeRounds,
            bFightTimeRounds: bFightTimeRounds,
            rTotalFights: (props.data[props.index].r_wins + props.data[props.index].r_losses + props.data[props.index].r_draws),
            bTotalFights: (props.data[props.index].b_wins + props.data[props.index].b_losses + props.data[props.index].b_draws),

            rColorIndex: "#FF5858",

            bColorIndex: "#4c9cf8",


        },

    ];

    return (

        <ResponsiveContainer width={"99%"} height={"100%"}>
            <BarChart

                data={data}
                barCategoryGap={5}
                barGap={20}

                margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="stat" xAxisId='red' tickLine={false} tick={<CustomizedLabelXAxis />} />



                <YAxis unit={" sec"} domain={[0, "dataMax + 150"]} />
                <Tooltip shared={false} content={<CustomeToolTip />} cursor={{ fill: "transparent" }} />
                <Legend content={<CustomLegend />} verticalAlign='top' />
                <Bar dataKey="rData" xAxisId='red' icon={<GiPunch />} name={data[0].rName} color="#FF5858" >

                    <LabelList content={<CustomizedLabelBar fightTime={data[0].rFightTime} />} />
                    {
                        data.map((entry, index) => (

                            < Cell key={`cell-${index}`} dataKey="rName" fill={entry.rColorIndex} fillOpacity={"55%"} stroke={entry.rColorIndex} strokeLinejoin='round' strokeWidth={3.5} />
                        ))

                    }</Bar>
                <Bar dataKey="bData" icon={<GiPunch />} name={data[0].bName} color="#4c9cf8" xAxisId='red'  >
                    <LabelList content={<CustomizedLabelBar fightTime={data[0].bFightTime} />} />
                    {
                        data.map((entry, index) => (

                            < Cell key={`cell-${index}`} dataKey="bName" fill={entry.bColorIndex} fillOpacity={"55%"} stroke={entry.bColorIndex} strokeLinejoin='round' strokeWidth={3.5} />
                        ))

                    }</Bar>
            </BarChart>
        </ResponsiveContainer>
    )
};


const CustomizedLabelBar = (props) => {
    const { x, y, fightTime } = props;
    const payload = props;
    return (<g  >

        <g>
            {<GiSandsOfTime className='bar_icon' x={x} y={y + 8} stroke="black" strokeWidth={1} style={{ fill: "white" }} />}
        </g>
        <text className='strikes_LA_bar-value' x={x + 5} y={y - 5} fontSize={30} style={{ fill: payload.fill, stroke: "white" }} >
            {fightTime}</text>









    </g>
    );
}

const CustomeToolTip = ({ active, payload }) => {


    if (active && payload && payload.length) {
        const fightTimeMS = payload[0].dataKey === "rData" ? payload[0].payload.rFightTime : payload[0].payload.bFightTime;

        const fightTimeRounds = payload[0].dataKey === "rData" ? payload[0].payload.rFightTimeRounds : payload[0].payload.bFightTimeRounds;

        const fightCount = payload[0].dataKey === "rData" ? payload[0].payload.rTotalFights : payload[0].payload.bTotalFights;
        return (

            <div className='custom-tooltip' style={{
                boxShadow: payload[0].dataKey === "rData" ? `0px 0px 9px ${payload[0].payload.rColorIndex}` : `0px 0px 9px ${payload[0].payload.bColorIndex}`
            }}>

                <h3 style={{ color: payload[0].dataKey === "rData" ? payload[0].payload.rColorIndex : payload[0].payload.bColorIndex }}>
                    {payload[0].name}
                </h3>


                <div>
                    <text className='stat_header'>Average Fight Time</text>
                    <p ><b>{fightTimeMS}</b> min:sec</p>
                    <p ><b>{fightTimeRounds}</b> round(s)</p>

                </div>


                <div>
                    <text className='stat_header'>Fight Count</text>
                    <p><b>{fightCount}</b> fights</p>
                </div>


            </div >
        );
    }

    return null;
};


const CustomLegend = (props) => {
    const payload = props.payload;
    return (
        <g className='AD_legend'>
            {
                payload.map((entry, index) => (

                    <div key={`item-${index}`} >
                        <span style={{ color: entry.payload.color, paddingRight: "4px" }}>{entry.payload.icon}</span>
                        <span style={{ color: entry.payload.color }} >{entry.value}</span>
                    </div>

                ))

            }
        </g>

    )
};
const CustomizedLabelXAxis = (props) => {
    const { index, x, y, payload } = props;


    return (

        <g className='custom_XAxis-Label_LA-aft' transform={`translate(${x},${y + 4})`} color={"white"} fill={"grey"} >



            <text x={0} y={0} dy={16} textAnchor="middle" fontSize={20} >

                {payload.value}

            </text>
            <g transform={`translate(${-100},${-2})`}> <GiSandsOfTime size={20} /></g>

        </g >


    );

};
export { AvgFightTimeChart };