import React from 'react'
import "./fighter_stats.css"



const RedStats = (props) => {
  return (

    <div>
      <h3>Red Corner</h3>

      <div className='iq__fighter-stats'>
        <h4>Name: </h4>
        <p>{props.data[props.index].r_name}</p>
        <h4>Height: </h4>
        <p>{props.data[props.index].r_height} In.</p>
        <h4>Weight Class:</h4>
        <p> {props.data[props.index].r_weight} Lb.</p>
        <h4>Reach: </h4>
        <p>{props.data[props.index].r_reach} In.</p>
        <h4>Stance: </h4>
        <p>{props.data[props.index].r_stance}</p>
      </div>


    </div>
  )
}



const BlueStats = (props) => {

  return (

    <div>

      <h3>Blue Corner</h3>

      <div className='iq__fighter-stats'>
        <h4>Name: </h4>
        <p>{props.data[props.index].b_name}</p>
        <h4>Height: </h4>
        <p>{props.data[props.index].b_height} In.</p>
        <h4>Weight Class:</h4>
        <p> {props.data[props.index].b_weight} Lb.</p>
        <h4>Reach: </h4>
        <p>{props.data[props.index].b_reach} In.</p>
        <h4>Stance: </h4>
        <p>{props.data[props.index].b_stance}</p>
      </div>


    </div>
  )
}
export { RedStats, BlueStats }