import React from 'react'
import './header.css'
import { Link } from 'react-router-dom'

const Header = (props) => {

  const signUpInput = (value) => {
    props.handleGetStartedEmailInput(value);
  };

  if (props.loggedIn && (props.subscriptionStatus === "active" || props.subscriptionStatus === "LIFETIME")) {
    return (
      <div className='iq__header section__padding'>
        <div className='iq__header-content'>

          <h1 className='gradient__text'>  History repeats itself. Predictions generated based on historical data.</h1>

          <p>Harness the power of Artificial Intelligence trained on historical fight data for precise
            percentage-based win predictions with FightxIQ.
          </p>
          <Link to={"/predictions"}> <p className='iq__sub-cta'>View Dashboard</p></Link>

        </div>
        <div className='iq__header-image'>
        </div>
      </div>
    )
  }


  if (props.loggedIn && props.subscriptionStatus === "Inactive") {
    return (
      <div className='iq__header section__padding'>
        <div className='iq__header-content'>

          <h1 className='gradient__text'>  History repeats itself. Predictions generated based on historical data.</h1>

          <p>Harness the power of Artificial Intelligence trained on historical fight data for precise
            percentage-based win predictions with FightxIQ.</p>
          <Link to={"/account"}> <p className='iq__sub-cta'>Subscribe For Access</p></Link>

        </div>
        <div className='iq__header-image'>
        </div>
      </div>
    )
  }

  return (
    <div className='iq__header section__padding'>
      <div className='iq__header-content'>
        <h1 className='gradient__text'>
          History repeats itself. </h1>
        <h1 className='gradient__text'>Predictions generated based on historical data.</h1>
        <p>Harness the power of Artificial Intelligence trained on historical fight data for precise
          percentage-based win predictions with FightxIQ. </p>

        <div className='iq__header-content__input'>

          <input onChange={(e) => signUpInput(e)} type="email" placeholder="your email address" />
          <button onClick={props.handleRegClick} type='button'>Get Started</button>
        </div>



      </div>
      <div className='iq__header-image'>
      </div>
    </div>
  )
}

export default Header