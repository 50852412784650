import React from 'react';
import "./TermsOfService.css";
import TermsOfServiceContent from './TermsOfServiceContent';


const TermsOfService = () => {
    return (
        <div className='tos-page'>


            <div className='tos-intro'>
                <h1>Terms Of Service</h1>
            </div>


            <div className='tos'>
                <div className='tos-container'>

                    <TermsOfServiceContent />

                </div>
            </div>
        </div>
    )
};

export default TermsOfService;