import React, { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { RiCloseLine } from 'react-icons/ri';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthService from "../../../services/auth.service";
import "./register.css";
import { useNavigate } from "react-router-dom";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;



const Register = (props) => {
    const [username, setUsername] = useState("");
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [password, setPassword] = useState("");
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState()

    const userRef = useRef();
    const errRef = useRef();
    const navigator = useNavigate();
    useEffect(() => {
        userRef.current.focus();
    }, []);
    useEffect(() => {
        if (props.quickStartEmail != undefined) {
            setEmail(props.quickStartEmail);
        }

    }, [props.quickStartEmail]);


    useEffect(() => {
        setValidName(USER_REGEX.test(username));
    }, [username]);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(password));
        setValidMatch(password === matchPassword);
    }, [password, matchPassword])

    useEffect(() => {

        setValidEmail(EMAIL_REGEX.test(email));

    }, [email]);

    useEffect(() => {
        setMessage('');
    }, [username, password, matchPassword]);



    const handleRegisterClick = () => {
        props.handleRegClick();
    };



    const handleRegister = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);


        if (username.length !== 0 & password.length !== 0) {
            AuthService.register(
                username,
                email,
                password
            ).then(
                (response) => {
                    props.setIsShowRegister(!props.isShowRegister);

                    props.showToastMessage(response.data.message, "success");
                    setEmail("");
                    setUsername("");
                    setPassword("");
                    setMatchPassword("");
                    navigator("/");
                    navigator(0);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||

                        error.message ||
                        error.toSting();

                    setMessage(resMessage);
                    props.showToastMessage(resMessage, "error");


                }
            );
            setLoading(false);
        }
    }

    return (


        <section className={`${props.isShowRegister ? "active" : ""} show`}>
            {/*<p ref={errRef} className={message ? "errmsg" : "offscreen"} aria-live="assertive">{message}</p>*/}

            <form className="register_form" onSubmit={handleRegister}>
                <div className="exitRegister">
                    <span><RiCloseLine color='#fff' size={27} onClick={handleRegisterClick} /></span>
                </div>
                <div className="formControl">
                    <h1>Register</h1>

                    <div>
                        <label htmlFor="email">Email
                            <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="email"
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="emailnote"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                        />
                        <p id="emailnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Please enter a valid E-mail address<br />

                        </p>

                    </div>
                    <div>
                        <label htmlFor="username">
                            Username:
                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validName || !username ? "hide" : "invalid"} />
                        </label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                        />
                        <p id="uidnote" className={userFocus && username && !validName ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            4 to 24 characters.<br />
                            Must begin with a letter.<br />
                            Letters, numbers, underscores, hyphens allowed.
                        </p>
                    </div>
                    <div>
                        <label htmlFor="password">
                            Password:
                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPwd || !password ? "hide" : "invalid"} />

                        </label>
                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            aria-invalid={validPwd ? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                        />
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters:
                            <span role="list" aria-label="exclamation mark">!</span>
                            <span role="list" aria-label="at symbol">@</span>
                            <span role="list" aria-label="hashtag">#</span>
                            <span role="list" aria-label="dollar sign">$</span>
                            <span role="list" aria-label="percent">%</span>

                        </p>
                    </div>
                    <div>
                        <label htmlFor="confirm_pwd">
                            Confirm Password:
                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPassword ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPassword ? "hide" : "invalid"} />

                        </label>
                        <input
                            type="password"
                            id="confirm_pwd"
                            onChange={(e) => setMatchPassword(e.target.value)}
                            value={matchPassword}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                        />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </p>
                    </div>
                    <label className="tos_agreement">By signing up, you agree to our <a href="/tos">Terms of Service</a> and <a href="/privacy">Privacy Policy</a></label>

                    <button disabled={!validName || !validPwd || !validMatch ? true : false || loading}> Sign Up</button>
                </div>
                <p>
                    Already registered?<br />
                    <span className="line">
                        <label onClick={props.handleLoginClick}>Sign In</label>
                    </span>
                </p>
            </form>

        </section>

    )




}

export default Register;