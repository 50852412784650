import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./AppRouter"
import './index.css'
import './App.css'
import ScrollToTop from "./components/scrollTop/ScrollToTop";
import { ToastContainer } from "react-toastify";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <Router>




    <ToastContainer autoClose={5000} limit={3} toastStyle={{ backgroundColor: "#040C18", color: "#81AFDD", fontWeight: 600 }} />
    <ScrollToTop />

    <AppRouter />





  </Router>
);