import React from 'react'
import './cta.css'
const Cta = (props) => {
  return (
    <div className='iq__cta'>
      <div className='iq__cta-content'>
        <p>
          Increase Your Betting IQ Today

        </p>
        <h3>Register today to access upcoming fight predictions!</h3>
      </div>
      <div className='iq__cta-button'>
        <button onClick={props.handleRegClick} type='button'>Get Started</button>
      </div>
    </div>
  )
}

export default Cta