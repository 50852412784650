import React from 'react';
import "./PrivacyPolicy.css";
import PrivacyPolicyContent from './PrivacyPolicyContent';
const PrivacyPolicy = () => {
    return (
        <div className='tos-page'>


            <div className='tos-intro'>
                <h1>Privacy Policy</h1>
            </div>


            <div className='tos'>
                <div className='tos-container'>

                    <PrivacyPolicyContent />

                </div>
            </div>
        </div>)
}

export default PrivacyPolicy