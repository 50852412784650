import React from 'react';
import "./prediction_display.css"




const PredictionDisplay = (props) => {


    const fightData = props.pieData[props.fightIndex];

    const outlineShadow = props.pieIndex === 0 ? "0px 0px 9px #ff0000" : "0px 0px 9px #5aa4f8";

    const fillColor = props.pieIndex === 0 ? 'rgb(231,60,78)' : 'rgba(54, 162, 235)';
    const headerShadow = props.pieIndex === 0 ? "2px 1px 1px #ff0000" : "2px 1px 1px #5aa4f8";

    const activeDataPrefix = props.pieIndex === 0 ? "r_" : "b_";

    const prediction_value = fightData[activeDataPrefix.concat("probability")] > .50 ? "WINNER" : "LOSER";



    const dataMissing = fightData[activeDataPrefix.concat("null_count")] > 0 ? "TRUE" : "FALSE";




    return (
        <div className='display_main' style={{ boxShadow: outlineShadow }}>

            <div className='display_section' >

                <div className='display_left-section fighter_name' >

                    <h3 >{fightData[activeDataPrefix.concat("name")]}</h3>

                </div>

                <div className='display_left-section fighter_age'>

                    <h3 className='display_stat-header'>Age</h3>

                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("age")]}</p>


                </div>
                <div className='display_left-section win_lose_draw'>
                    <div className='win'>
                        <h3 className='display_stat-header'>Wins</h3>
                        <p className='display_stat-value'>{fightData[activeDataPrefix.concat("wins")]}   </p>

                    </div>
                    <div className='lose'>

                        <h3 className='display_stat-header'>Losses</h3>
                        <p className='display_stat-value'>{fightData[activeDataPrefix.concat("losses")]}</p>
                    </div>
                    <div className='draw'>
                        <h3 className='display_stat-header'>Draws</h3>
                        <p className='display_stat-value'>{fightData[activeDataPrefix.concat("draws")]}</p>
                    </div>
                </div>


                <div className='display_left-section title_fight'>

                    <h3 className='display_stat-header'>Title Fights</h3>
                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("title_fight_count")]}</p>
                </div>

                <div className='display_left-section leg_reach'>

                    <h3 className='display_stat-header'>Leg Reach</h3>
                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("leg_reach")]} in.</p>
                </div>

                <div className='display_left-section data_missing'>

                    <h3 className='display_stat-header'>Data Missing?</h3>
                    <p className='display_stat-value'>{dataMissing}</p>

                </div>

                <div className='display_left-section five_round-count'>

                    <h3 className='display_stat-header'>5 Round Count</h3>
                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("five_round_fight_count")]}</p>
                </div>

                <div className='display_left-section avg_fight_time'>

                    <h3 className='display_stat-header'>Avg. Fight Time</h3>
                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("avg_fight_time")]}</p>

                </div>


                <div className='display_left-section strikes_landed'>

                    <h4 className='display_stat-header'>Strikes Landed</h4>
                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("career_significant_strikes_landed")]}</p>

                </div>
                <div className='display_left-section strikes_attempted'>
                    <h4 className='display_stat-header'>Strikes Attempted</h4>

                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("career_significant_strikes_attempted")]}</p>
                </div>
                <div className='display_left-section takedowns_landed'>

                    <h4 className='display_stat-header'>TD Landed</h4>
                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("career_takedowns_landed")]}</p>

                </div>
                <div className='display_left-section takedowns_attempted'>


                    <h4 className='display_stat-header'>TD Attempt</h4>
                    <p className='display_stat-value'>{fightData[activeDataPrefix.concat("career_takedowns_attempted")]}</p>

                </div>


            </div>




            <div className='display_right-section'>

                <div className='right_section-prediction'>
                    <span className='right_section-prediction_header' textAnchor="middle" style={{ "color": "white", "textShadow": headerShadow }}>
                        Prediction
                    </span>
                    <span x={0} y={0} className='right_section-prediction_value' style={{ "color": fillColor }}>
                        {prediction_value}
                    </span>

                </div>


                <div className='right_section-win_chance'>
                    <span className='right_section-win_header' textAnchor="middle" style={{ "color": "white", "textShadow": headerShadow }}>
                        Win Chance
                    </span>

                    <span x={0} y={0} className='right_section-win_value' style={{ "color": fillColor }}>
                        {Math.round(fightData[activeDataPrefix.concat("probability")] * 100)}%
                    </span>
                </div>
                {/*<div>
                    <h3>Last Fought</h3>
    </div>*/}

            </div>
        </div >
    )
}

export default PredictionDisplay