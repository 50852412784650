import React, { useState } from 'react'
import onClickOutside from 'react-onclickoutside';
import { RiCloseLine } from 'react-icons/ri';
import { CgChevronDown } from 'react-icons/cg';


import "./new_dropdown.scss";


function NewDropdown({ data, setIndexes, index }) {
    const [open, setOpen] = useState(false);
    const [selection, setSelection] = useState([]);
    const toggle = () => setOpen(!open);

    NewDropdown.handleClickOutside = () => setOpen(false);

    const handleOnClick = (item, index) => {

        if (!selection.some(current => current.index === index)) {
            setSelection([item]);
            setIndexes(index);
            toggle();
        }
    }




    return (
        <div className='iq__predictions-container_dropdown'>
            <div className='iq__dd-wrapper'>
                <div
                    tabIndex={0}
                    className='iq__dd-header'
                    role='button'
                    onClick={() => toggle(!open)}
                >
                    <div className='iq__dd-header_title'>
                        <p className='iq__dd-header_title--bold'>
                            {data[index].r_name + " vs " + data[index].b_name}
                        </p>
                    </div>
                    <div className='iq__dd-header_action'>
                        <p>{open ? <RiCloseLine color='#fff' size={27} />
                            : <CgChevronDown color='#fff' size={27} />}</p>

                    </div>

                </div>
                {open && (
                    <ul className='iq__dd-list'>
                        {data.map((item, index) => (

                            <li className='iq__dd-list-item' key={index}>
                                <button type='button' onClick={() => handleOnClick(item, index)}>
                                    <span>{item.r_name + " vs " + item.b_name}</span>

                                </button>

                            </li>
                        ))}
                    </ul>
                )}


            </div>
        </div>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () => NewDropdown.handleClickOutside,
};
export default onClickOutside(NewDropdown, clickOutsideConfig);