import React from 'react'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { GiPunch } from "react-icons/gi"
import "./chart_styling.css"
import "../chart_tooltip_styling.css";

const FighterAverages = (props) => {
    const data = [

        {
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",
            stat: "Knockdown Avg.",
            bName: props.data[props.index].b_name,
            rName: props.data[props.index].r_name,
            Red: props.data[props.index].r_knockdown_avg,
            Blue: props.data[props.index].b_knockdown_avg,


        },
        {
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",
            stat: "Takedown Avg.",
            bName: props.data[props.index].b_name,
            rName: props.data[props.index].r_name,
            Red: props.data[props.index].r_career_takedown_avg,
            Blue: props.data[props.index].b_career_takedown_avg,
        },
        {
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",
            stat: "Submission Avg.",
            bName: props.data[props.index].b_name,
            rName: props.data[props.index].r_name,

            Red: props.data[props.index].r_career_submission_average,
            Blue: props.data[props.index].b_career_submission_average
        },

    ]
    return (
        <ResponsiveContainer width={"99%"} height={"100%"}>
            <BarChart
                data={data}
                width={700}
                height={500}
                barCategoryGap={7}
                barGap={3}
                margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis dataKey="stat" xAxisId="statAxis" tickLine={false} tick={customizedLabelXAxis} interval={0} >

                </XAxis>
                <YAxis domain={[0, dataMax => (~~dataMax + 2)]} />
                <Tooltip content={<CustomeToolTip />} cursor={{ fill: "white", opacity: "15%" }} />
                <Legend content={<CustomLegend />} verticalAlign='top' align='center' />
                <Bar name={data[0].rName} dataKey="Red" fill="#FF5858" icon={<GiPunch />} xAxisId="statAxis" fillOpacity={"55%"} stroke={"#FF5858"} strokeLinejoin='round' strokeWidth={3.5}>
                    <LabelList content={<CustomizedLabelBar dataKey={"Red"} fill="#FF5858" />} position="top" />



                </Bar>

                <Bar name={data[0].bName} dataKey="Blue" fill="#4c9cf8" icon={<GiPunch />} xAxisId="statAxis" fillOpacity={"55%"} stroke={"#4c9cf8"} strokeLinejoin='round' strokeWidth={3.5} >
                    <LabelList content={<CustomizedLabelBar dataKey={"Blue"} fill="#4c9cf8" />} position="top" />


                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
const CustomLegend = (props) => {
    const payload = props.payload;
    return (
        <span className='AD_legend'>
            {
                payload.map((entry, index) => (

                    <div key={`item-${index}`}>
                        <span style={{ color: entry.color, paddingRight: "4px", verticalAlign: "middle" }}>{entry.payload.icon}</span>
                        <span style={{ color: entry.color }} >{entry.value}</span>
                    </div>

                ))

            }
        </span>

    )
};
const CustomeToolTip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
        return (
            <div className='custom-tooltip' style={{ boxShadow: "0px 0px 9px white" }}>


                <div className='multi-stat_header'>
                    <div className='custom-tooltip_icon'>
                        {payload[0].payload.icon}
                    </div>
                    <h3 >



                        {payload[0].payload.stat}
                        <p>per 15 min.</p>
                    </h3>

                </div>

                <div className='custom-toolip_multi-div'>
                    <div className='custom-toolip_multi-unit'>
                        <b style={{ color: payload[0].payload.rColorIndex }}>{payload[0].name}</b>
                        <p > <b>{payload[0].value}</b></p>

                    </div>


                    <div className='custom-toolip_multi-unit'>
                        <b style={{ color: payload[0].payload.bColorIndex }}>{payload[1].name}</b>
                        <p > <b>{payload[1].value}</b></p>
                    </div>
                </div>





            </div>
        );
    }
    return null;
};
const CustomizedLabelBar = (props) => {
    const { x, y, width, height, value, index, dataKey } = props;
    const payload = props;
    return (
        <g >



            <text className='strikes_LA_bar-value' x={x} y={y - 5} fontSize={30} textAnchor='start' style={{ fill: payload.fill, stroke: "white" }} >
                {value}

            </text>









        </g>


    );
}

const customizedLabelXAxis = (props) => {
    const { index, x, y, payload } = props;

    return (

        <g className='custom_XAxis-Label_LA' transform={`translate(${x - 10},${y})`} >



            <text x={10} y={0} dy={16} textAnchor="middle" fill={"grey"}  >

                {payload.value}

            </text>

        </g >


    );

};

export { FighterAverages };