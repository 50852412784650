import React from 'react'
import { Link } from 'react-router-dom'
import "./features.css"
import { Feature } from "../../components"
const featuresData = [

  {
    title: 'Predictions based on history',
    text: 'To build a great machine learning model you need data. Our predictions are based off of over 20 years of UFC fight data.'
  },
  {
    title: 'Multiple Classifications',
    text: 'Our machine learning model is powerful. The model stacks multiple classifications allowing for more accurate UFC fight predictions'
  },
  {
    title: 'Level The Playing Field',
    text: "The biggest bookies like DraftKings, Stake ,Mybookie, PrizePicks  and many more all utilize predictive AI technology to influence their odds.With FightxIQ, you're bringing yourself to their level. A fair playing field"
  },
  {
    title: 'Improved Insight',
    text: 'Data is your friend. Each fight prediction includes data visualizations giving you more insight into each fighter and what they are capable of.'
  },
]



const Features = (props) => {

  if (props.loggedIn && (props.subscriptionStatus === "active" || props.subscriptionStatus === "LIFETIME")) {
    return (
      <div className='iq__features section__padding' id='#features'>
        <div className='iq__features-heading'>
          <h1 className='gradient__text'>Beat The Bookies!<br /> Level The Playing Field. </h1>
          <Link to={"/predictions"}> <p className='iq__sub-cta'>View Dashboard</p></Link>

        </div>

        <div className='iq__features-container'>
          {featuresData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>

      </div>
    )
  }

  if (props.loggedIn && props.subscriptionStatus === "Inactive") {
    return (
      <div className='iq__features section__padding' id='#features'>
        <div className='iq__features-heading'>

          <h1 className='gradient__text'>Beat The Bookies!<br /> Level The Playing Field. </h1>
          <Link to={"/account"}> <p className='iq__sub-cta'>Subscribe For Access</p></Link>

        </div>

        <div className='iq__features-container'>
          {featuresData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>

      </div>
    )
  }


  return (
    <div className='iq__features section__padding' id='#features'>
      <div className='iq__features-heading'>
        <h1 className='gradient__text'>Beat The Bookies!<br /> Level The Playing Field. </h1>
        <p onClick={props.handleRegClick}>Sign Up for access</p>
      </div>
      <div className='iq__features-container'>
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Features